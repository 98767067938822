<template>
  <div id="error">
    <h1>404 Error</h1>
    <h3>The page you are on doesn't or no longer exists</h3>
  </div>
</template>

<script>
export default {};
</script>

<style>
#error {
  text-align: center;
  margin-top: 10%;
}
</style>
